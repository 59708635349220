import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { 
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Button,
  Input,
 } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination_ansync"
import Select from "react-select";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getIssabelReportFilter, getIssabelReportList } from "../../helpers/backend";
import "../Whatsapp/Chat.css"
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import 'flatpickr/dist/l10n/es.js'; // Importa el idioma español



const IssabelReport = () => {
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [estado, setEstado] = useState('');
  const [numero, setNumero] = useState('');
  const [loading, setLoading] = useState(false); 
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

const getReportData = async () => {
  const response = await getIssabelReportList();
  setLoading(true);
  setParams({
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results,
  });
  setLoading(false);
};

  const filterDate = async() => {
    setLoading(true);
    const response = await getIssabelReportFilter(`?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&numero=${numero}`);
    // const response = await getIssabelReportFilter(``);

    console.log(response);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      setFechaInicio(start.toISOString().split('T')[0]); // Formato YYYY-MM-DD
      setFechaFin(end.toISOString().split('T')[0]); // Formato YYYY-MM-DD
      setSelectedDate(selectedDates)
    }
  };
  const resetSearch = () => {
    setFechaFin("");
    setFechaInicio("");
    setSelectedDate("");
    setEstado('');
    setNumero('');
    getReportData()
  };

  useEffect(() => {
    getReportData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getIssabelReportFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  const StatusBadge = ({ estado }) => {
    const estadoConfig = {
      ANSWERED: { text: "Contestada", className: "bg-success" },
      FAILED: { text: "Fallida", className: "bg-danger" },
      BUSY: { text: "Ocupado", className: "bg-warning" },
      CONGESTION: { text: "Congestión", className: "bg-info" },
      'NO ANSWER': { text: "Perdida", className: "", style: { background: "rgb(111, 66, 193)" } },
    };
    const config = estadoConfig[estado] || {};
    return (
      <span className={`badge ${config.className}`} style={config.style}>
        {config.text}
      </span>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Reportes Issabel </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Reportes Issabel "/>          
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                <Row className="d-flex justify-content-center">
                  <Col lg={12}>
                    <Row>
                    <Col>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder = "Fecha"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            locale: 'es',
                          }}
                          onChange={handleDateChange}
                          value= {selectedDate}
                        />
                      </Col>
                        <Col>
                        <Select
                          onChange={(e) => setEstado(e.value)}
                          options= {[
                            { value: 'ANSWERED', label: 'Contestada' },
                            { value: 'BUSY', label: 'Ocupado' },
                            { value: 'FAILED', label: 'Fallida' },
                            { value: 'NO ANSWER', label: 'Perdida' },
                            { value: 'CONGESTION', label: 'Congestion' }
                          ]}
                          placeholder="Estado"
                        />
                        </Col>
                        <Col>
                        <Input
                          type="number"
                          className="form-control"
                          name="nombre"
                          placeholder="Numero"
                          value={numero}
                          onChange={(e) => setNumero(e.target.value)}
                          
                        />
                      </Col>
                        <Col>
                        <Button color="warning" className="btn-sm me-1 mt-1" onClick={filterDate}>
                          <b>Consultar</b>
                        </Button>
                        <Button color="danger" className="btn-sm me-1 mt-1" onClick={resetSearch}>
                          <b>Borrar</b>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                </CardBody>
              </Card>
              <Card>
                {params.results ? (
                  <CardBody className="ps-5 pe-5">
                    <h2 className="mb-3 font-size-20">Reportes</h2>
                    <div className="table-responsive">
                    <Table className="table table-sm align-middle table-centered mb-3" style={{ textAlign: "center" }}>
                      <thead className="table-dark align-middle">
                          <tr>
                          <th>#</th>
                          <th>Fecha</th>
                          <th>Número de origen</th>
                          <th>Número de destino</th>
                          <th>Estado</th>
                          <th>Duración</th>
                          <th>Observaciones</th>
                          <th>Grabación</th>
                          </tr>
                      </thead>
                      <tbody>
                        {params.results.map((item, idx) => (
                        <tr key={idx}>
                          <th scope="row">{idx + 1}</th>
                          <td>{item.fecha}</td>
                          <td>{item.numero_origen}</td>
                          <td>{item.numero_destino}</td>
                          <td><StatusBadge estado={item.estado} /></td>
                          <td>{item.duracion}</td>
                          <td>{item.observaciones}</td>
                          <td>
                          <audio className="audio-player" controls>
                              <source src={item.grabacion} type="audio/mpeg" />
                              Tu navegador no soporta la reproducción de audio.
                          </audio>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl={`issabel/call_records/`}
                      filterFunction={getFilterData}
                    />

                      <Row className="col-lg-12 justify-content-center">
                    <Col md={2} >
                    <Link className="btn btn-secondary text-dark ms-5" to={`/dashboard/`}>
                        Volver
                      </Link>
                    </Col>
                  </Row>

                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default IssabelReport;
