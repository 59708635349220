export const LOGIN = "/auth/login/";
export const LOGOUT = "/auth/logout/";
export const ME = "/me/";
export const USER = "/users/";
export const USER_FULLLIST = "/users/FullList";
export const PERMISSION = "/users/permissions_visible/";
export const PERMISSION_USER = "/users/add_permissions_to_user/";
export const PERMISSION_GROUP = "/users/add_permissions_to_group/";
export const ROL = "/roles/";
export const ROL_FULLLIST = "/roles/FullList";
export const ACCOUNT = "/account/account/";
export const ACCOUNT_FULLLIST = "/account/account/FullList";
export const CAMPAIGN = "/campaign/campaign/";
export const CAMPAIGN_FULLLIST = "/campaign/campaign/FullList/";
export const AUDITS = "/audits/audits/";
export const AUDITS_FULLLIST = "/audits/audits/FullList";
export const LOTE = "/lote/lote/";
export const LOTE_FULLLIST = "/lote/lote/FullList";
export const LEADS = "/leads/leads/";
export const LEADS_FULLLIST = "/leads/leads/FullList";
export const CASOS = "/casos/casos/";
export const CASOS_FULLLIST = "/casos/casos/FullList";
export const DASHBOARD = "/dashboard/";
export const CONTACTS = "/contact/contacto/";
export const CONTACTS_GET_USER = "/contact/contacto/get_contact_user/";
export const CONTACTS_FULLLIST = "contact/contacto/FullList";
export const CONTACTS_DATA = "/contact/datoContacto/";
export const CONTACTS_DATA_FULLLIST = "/contact/datoContacto/FullList";
export const CONTACTS_DOCUMENT = "/contact/documentoContacto/";
export const CONTACTS_DOCUMENT_FULLLIST = "/contact/documentoContacto/FullList";
export const CONTACTS_AGENT = "/contact/contacto/get_contact_agent/";

//GESTION CONTACTO
export const CALIFICACION_CONTACTO = "/contact/calificacionContacto/";
export const CALIFICACION_CONTACTO_LISTA = "/contact/calificacionContacto/FullList";
export const DESCRIPCION_CALIFICACION = "/contact/descripcionCalificacion/";
export const DESCRIPCION_CALIFICACION_LISTA = "/contact/descripcionCalificacion/FullList";
export const TIPO_GESTION_CONTACTO = "/contact/tipoGestion/";
export const TIPO_GESTION_CONTACTO_LISTA = "/contact/tipoGestion/FullList";
export const GESTION_CONTACTO = "/contact/gestionContacto/";
export const GESTION_CONTACTO_FULLLIST = "/contact/gestionContacto/FullList";


//HOJA DE VIDA
export const HOJA_CONTACTO = "/contact/hoja_contacto/";
export const EXPERIENCIA_HOJA = "/contact/experiencia_hoja/";
export const EDUCACION_HOJA = "/contact/educacion_hoja/";
export const CONOCIMIENTO_HOJA = "/contact/conociminetos_hoja/";

//CALENDAR
export const CALENDAR_EVENT = "/calendar/event/";
export const CALENDAR_GESTION = "/calendar/gestion_event/";
//Tasks

export const TASKS = "/tasks/";
export const PROJECT = "/tasks/project/";

//SURVEY
export const SURVEY = "/survey/survey/";
export const SURVEY_QUESTION = "survey/survey_questions/";
export const SURVEY_ASSIGN = "/survey/survey_schedule/";
export const WHATHSAPP_CHAT = "whatsapp/message/contact_messages";

export const CONVERSATIONS = "whatsapp/conversation/"
export const PRODUCTOS = "/store/producto/";

//Pisos
export const PISO = "/hc/floor/";
export const PISO_FULLLIST = "/hc/floor/FullList";

//Areas
export const AREAS = "/hc/areas/";
export const AREAS_FULLLIST = "/hc/areas/FullList";

//Room  
export const ROOM = "/hc/room/";
export const ROOM_FULLLIST = "/hc/room/FullList";

//Patient
export const PATIENT = "/hc/patient/";

//Desinfeccion
export const DESINFECCION = "/hc/disinfection/";